import React, { useState, useEffect } from 'react';
import './ScrollToTopButton.css'; // Assure-toi d'avoir ton fichier CSS pour les styles

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Afficher ou cacher l'icône de la flèche en fonction du défilement de la page
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  // Remonter en haut de la page lorsque l'icône est cliquée
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={`scroll-to-top ${isVisible ? 'show' : ''}`}>
      <button onClick={scrollToTop}>
        <img src="icon/arrow_up.png" alt="Flèche vers le haut" />
      </button>
    </div>
  );
};

export default ScrollToTopButton;
