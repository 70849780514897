import logo from './logo.svg';
import './App.css';
import TopMenu from'./components/TopMenu';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import Home from './pages/Home';
import Carousel from './components/Carousel';
import MainMenu from './pages/MainMenu'
import Test from './pages/Test';
import NotFound from './pages/NotFound';
import Cat_render from './components/Gallery';
import ContactPage from './pages/Contact'
import ScrollToTopButton from './components/ScrollToTopButton';

function App() {
  return (
    <Router>
        <TopMenu />
        <ScrollToTopButton/>
        {/* <Carousel images={images}/> */}
        

      <Routes>
        <Route path="/" exact element={<MainMenu /> } />
        {/* <Route path="/Test" element={<Test />} /> */}
        <Route path="/Animalier" element={<Cat_render title="Animalier" />} />
        
        <Route path="/Voyages" element={<Cat_render title="Voyages" />} />
        <Route path="/Voyages/Dublin" element={<Cat_render title="Dublin" />} />
        <Route path="/Voyages/Nouvelle-Zélande" element={<Cat_render title="Nouvelle-Zélande" />} />
        <Route path="/Voyages/Caraïbes" element={<Cat_render title="Caraïbes" />} />

        <Route path="/la_montagne" element={<Cat_render title="La Montagne" />} />
        <Route path="/humain_volant" element={<Cat_render title="Humain Volant" />} />
        <Route path="/Monocrome" element={<Cat_render subFolder_to_sub_gal={true} title="Monocrome" />} />
        <Route path="/Monocrome/Argentique/" element={<Cat_render title="Argentique" />} />
        <Route path="/Monocrome/Numérique/" element={<Cat_render title="Numérique" />} />
        <Route path="Contact" exact element={<ContactPage />} />
        {/* Route pour l'erreur 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>

    
  );
};

// }

export default App;
