import React from 'react';

function NotFound() {
  return (
    <div>
      <h2>Erreur 404</h2>
      <p>La page que vous cherchez est introuvable.</p>
    </div>
  );
}

export default NotFound;