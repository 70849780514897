import React, { useEffect, useState } from 'react';
import { Navigate, redirect, useLocation } from 'react-router-dom';
import PhotoAlbum from 'react-photo-album';
import { Link,useNavigate } from 'react-router-dom';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";




const Gallery = ({ subFolder_to_sub_gal = false , title = '' }) => {

  const columns_number =  (containerWidth,lent)=>{
    let r = 0 
    if (containerWidth < 800) r = 1 ;
    else if (containerWidth < 1200) r = 2;
    else if (containerWidth < 1800) r = 3;
    else r = 4;
    if (r > lent) 
      return lent
    return r;}  

  const location = useLocation();
  const currentURL = location.pathname;
  const [isLoading, setIsLoading] = useState(true);
  const [galleryData, setGalleryData] = useState([]);
  const [folderData, setFolderData] = useState([]);
  const [index, setIndex] = useState(-1);
  const navigate = useNavigate();
  const [parsed_data, setparsed_data] = useState(-1);
  

  const zoomRef = React.useRef(null);
  

  const navigateToSub = (target) => {
    navigate(target + '/');
  };

  useEffect(() => {
    fetch('/db/ImageDB.json')
      .then((response) => response.json())
      .then((data) => {
        setparsed_data(data)
        console.log('/db/'+ currentURL)
        const [imagesToDisplay, foldersToDisplay] = processGalleryData(data, '/db/'+ currentURL);

        setGalleryData(imagesToDisplay);
        setFolderData(foldersToDisplay);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erreur lors du chargement du JSON :', error);
        setIsLoading(false);
      });
  }, [currentURL]);



  const processGalleryData = (data, url) => {
    if (!url || !data) {
      return [[],[]];
    }

    const extractImages = (folder, currentURL) => {
      const imagesToDisplay = [];
      for (const file of folder.contents.files) {
          let other_sizes = []
          for (const size_img_obj  of file.other_sizes){
            const other_sizes_element = {src: `/${size_img_obj.path}`, width: size_img_obj.width, height: size_img_obj.height }
            other_sizes.push(other_sizes_element)
          }
          // console.log(other_sizes)
          const reversedSizes = other_sizes.slice().reverse();
          other_sizes = reversedSizes;
          // console.log(other_sizes)

          imagesToDisplay.push({
            key: `/${file.path}`,
            // src: `/${file.path}`,
            width : file.width,
            height : file.height,
            srcSet: other_sizes
          });
          // console.log(other_sizes)
        // }
      }
      const FolderToDisplay = [];
      let first_picture = {}
      for (const file of folder.contents.folders) {
        if (folder.folder.startsWith(currentURL)) {
          // take the first image 
          first_picture =  file.contents.files[0]
          FolderToDisplay.push({
            src: `/${first_picture.path}`,
            width : first_picture.width,
            height : first_picture.height,
            title: file.folder
          });
        }
      }
      return [imagesToDisplay , FolderToDisplay] ;
    };
   
    let ProcessUrl = url.replace(/^\/|\/$/g, "");

    if (ProcessUrl.includes('/')){ // i need to find the folder in json DB for excute the same code
      const url_tab = ProcessUrl.split('/')
      let n_data  =  data
      ProcessUrl = url_tab[url_tab.length - 1];
      for (let i = 0; i < url_tab.length -1;i++){
       let parrentFolder  = url_tab[i]
        for (const rootFolder of data.folders) { 
          if (rootFolder.folder === parrentFolder) {
            n_data = rootFolder.contents
            data = n_data
            break
        }
      }
    }
    }
    if (data.contents){// specificase when croll a folder for sub galerie.
      return extractImages(data, ProcessUrl);
  }
    for (const rootFolder of data.folders) {
      if (decodeURI(ProcessUrl) === rootFolder.folder) {
        return extractImages(rootFolder, ProcessUrl);
      }

      for (const subFolder of rootFolder.contents.folders) {
        if (ProcessUrl === subFolder.folder) {
          return extractImages(subFolder, ProcessUrl);
        }
      }
    }

    return [];
  };
  if (!subFolder_to_sub_gal) {
    
    if (folderData.length > 0) {
      return (
        <div className="sub-gallery">
        <h2>{title}</h2>
        <PhotoAlbum layout="columns"  
          componentsProps={(containerWidth) => ({
                      imageProps: { loading: (containerWidth || 0) > 600 ? "lazy" : "lazy" },
                    })
                  }
          columns={(containerWidth) => columns_number(containerWidth, folderData.length)}
          renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
            <div
              href={photo.href}
              style={wrapperStyle}
              target="_blank"
              rel="noreferrer noopener"
              title= {photo.title}
            >
           {renderDefaultPhoto({ wrapped: true })}
           <a href={`${currentURL}/${photo.title}`} className="menu-link">
           <button className='menu-button' href>{photo.title}</button>
           </a>
          
        </div>
      )}
        photos={folderData} onClick={({ index }) =>navigateToSub(folderData[index].title)} />

        </div>
    );
    } else if (galleryData.length > 0) {
      return (
        <div className="gallery">
          <h2>{title}</h2>
          <PhotoAlbum
                      componentsProps={(containerWidth) => ({
                        imageProps: { loading: (containerWidth || 0) > 600 ? "lazy" : "lazy" },
                      })
                    }
            layout="columns"
            columns={(containerWidth) => columns_number(containerWidth, galleryData.length)}
            photos={galleryData}
            onClick={({ index }) => setIndex(index)}
          />
          <Lightbox
            slides={galleryData}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            plugins={[Thumbnails,Slideshow,Fullscreen]}
            // zoom={{ ref: zoomRef }}
            // ... (plugins pour le lightbox)
          />
        </div>
      );
    } else {
      return <div>Aucune donnée à afficher.</div>;
    }
  } else {
    if (folderData.length > 0) { // la parte multiple galllerie comme monocrome
    const  recursive_find_folder = (to_find,jsons_crawler,star_point=[])=>{

      for (const obj of jsons_crawler.folders){
        //folder is foldername
        if (obj.folder === to_find){
          return(obj);
        }else {
          star_point.push(obj.contents)
      }
    }
      return recursive_find_folder(to_find,star_point.pop(),star_point)
  }
  const do_render = (folder_structure) => {
    const renderedFolders = folder_structure.map((subFolder, folderIndex) => {
      const starting_point = recursive_find_folder(subFolder.title, parsed_data);
      const [imagesToDisplay, foldersToDisplay] = processGalleryData(starting_point, starting_point.folder);
      return (
        <div key={folderIndex}>
          <h2>{subFolder.title}</h2>
          <PhotoAlbum
            layout="columns"
            // #ici eager
            componentsProps={(containerWidth) => ({
              imageProps: { loading: (containerWidth || 0) > 600 ? "lazy" : "lazy" },
            })
          }

            columns={(containerWidth) => columns_number(containerWidth, imagesToDisplay.length)}
            photos={imagesToDisplay}
            onClick={({ index }) => navigateToSub(subFolder.title)}
            plugins={[Thumbnails,Slideshow,Fullscreen]}
          
          />
        </div>
      );
    });
  
    return (
      <div className="sub-gallery">
        {renderedFolders.length > 0 ? renderedFolders : <div>Aucune donnée à afficher.</div>}
      </div>
    );
  };
  
  // Utilisation de la fonction do_render
  if (subFolder_to_sub_gal) {
    return do_render(folderData);
  } else {
    // Votre logique pour le rendu lorsque subFolder_to_sub_gal est faux
    // Par exemple :
    return <div>Aucun sous-dossier à afficher.</div>;
  }
  }}
};

export default Gallery;