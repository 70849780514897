import React from 'react';
import { Link } from 'react-router-dom';
const categories = [
  {
    name: 'Animalier',
    image: 'db/Animalier/JAS_2716_dng.webp', // Chemin de l'image d'illustration
    redirect: 'Animalier',
    width: 1080,
    height: 720
  },
  {
    name: 'Voyages',
    image: 'db/Voyages/Nouvelle-Zélande/JAS_2006.webp',
    redirect: 'Voyages',
    width: 1574,
    height: 1049
  },
  {
    name: 'La Montagne',
    image: 'db/la_montagne/JAS_4528.webp',
    redirect: 'la_montagne',
    width: 1574,
    height: 1049
  },
  {
    name: 'Humain Volant',
    image: 'db/humain_volant/JAS_3450.webp',
    redirect: 'humain_volant',
    width: 1575,
    height: 973,
  },
  {
    name: 'Noir et Blanc',
    image: 'db/Monocrome/Numérique/_JAS5239.webp',
    redirect: 'Monocrome',
    width: 1575,
    height: 1049,
  },
  // {
  //   name: 'Contact',
  //   image: '/contact.jpg',
  // },
  // {
  //   name: 'Accueil',
  //   image: '/accueil.jpg',
  // },
];

// CLS 
// img[Attributes Style] {
//   aspect-ratio: auto 640 / 360;
// }
function MainMenu() {
  return (
    <div className="main-menu">
      <h1>Mes Galeries</h1>
      <div className="menu-list">
        {categories.map((category, index) => (
          <div key={index} className="menu-item">
            <a href={`/${category.redirect}`} className="menu-link">
              
              <img src={category.image} alt={category.name} 
                style={{
                  aspectRatio: `${category.width} / ${category.height}`,
                  height: '50vw'
                }} />
              <button className="menu-button">{category.name}</button>
            </a>
          </div>
        ))}
      </div>
      <a className='Instagram_logo' href='https://www.instagram.com/jason_chenaud/?hl=fr'>
        <img   src='logo_instagram.png'/>
        </a>
    </div>
  );
}


export default MainMenu;









