import React from 'react';
import './Contact.css';

const ContactPage = () => {
  const copyEmail = () => {
    const email = 'jchenaud.photographies@gmail.com';
    navigator.clipboard.writeText(email).then(() => {
      alert('L\'adresse e-mail a été copiée !');
    }).catch((err) => {
      console.error('Impossible de copier l\'adresse e-mail : ', err);
    });
  };

  return (
    <div className='ContactPage'>
      <h1 className='h1' style={{ fontSize: '4em' }}>Contact</h1>
      <p>Vous pouvez me contacter à l'adresse mail suivante :</p>
      <p>
        <span style={{ color: 'blue', cursor: 'pointer' }} onClick={copyEmail}>
          jchenaud.photographies@gmail.com
        </span>
      </p>
    </div>
  );
};

export default ContactPage;