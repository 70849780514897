// Fichier : Home.js

import React from 'react';
import Carousel from '../components/Carousel';

const images = [
    "/logo192.png",
    "/logo512.png",
    "/Annimalier/JAS_1274.jpg",
    "/Annimalier/JAS_2456_dng.jpg",
    "/Annimalier/JAS_2971.jpg",
    "/Annimalier/JAS_3993_insta.jpg",
    "/Annimalier/JAS_3999_01.jpg",
    "/Annimalier/JAS_4211_03.jpg",
    "/Annimalier/JAS_5229.jpg",
    "/Annimalier/JAS_6345.jpg",
    "/Annimalier/JAS_6396.jpg",
    "/Annimalier/JAS_6487.jpg",
    "/Annimalier/JAS_6970.jpg",
    "/Annimalier/les Passereaux/JAS_2716_dng.jpg",
    "/humain volant/JAS_3450.jpg",
    "/humain volant/JAS_4520.jpg",
    "/humain volant/JAS_6362.jpg",
    "/la montagne/JAS_4528.jpg",
    "/la montagne/JAS_5012.jpg",
    "/la montagne/JAS_5167.jpg",
    "/la montagne/JAS_5185.jpg",
    "/les voyages/Dublin 2018/P1000857.jpg",
    "/les voyages/Dublin 2018/P1000858.jpg",
    "/les voyages/Dublin 2018/P1000991.jpg",
    "/les voyages/Dublin 2018/P1010051.jpg",
    "/les voyages/Nz/JAS_0956_DNG.jpg",
    "/les voyages/Nz/JAS_0961_DNG.jpg",
    "/les voyages/Nz/JAS_0973_DNG-2.jpg",
    "/les voyages/Nz/JAS_1308_DNG.jpg",
    "/les voyages/Nz/JAS_1907.jpg",
    "/les voyages/Nz/JAS_1935_DNG.jpg",
    "/les voyages/Nz/JAS_2006_04.jpg",
    "/les voyages/Nz/JAS_2068_DNG.jpg",
    "/les voyages/Nz/JAS_2190.jpg",
    "/les voyages/Nz/not perfect/JAS_1713_DNG.jpg",
    "/les voyages/Nz/not perfect/JAS_1737_DNG.jpg"
  ];


function Home() {
  return (
    <div>
      <h1>Bienvenue sur Mon Site de Photographie</h1>
      <p>Découvrez mes collections de photographies exceptionnelles.</p>
      <Carousel imagePaths={images} />
    </div>
  );
}

export default Home;





