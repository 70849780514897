import React, { useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu,sidebarClasses } from 'react-pro-sidebar';
import 'react-pro-sidebar'
import { Link } from 'react-router-dom';

const TopMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return(
    <div>
    <div className="menu-icon" onClick={toggleMenu}>
    <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
    <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
    <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
  </div>

    <Sidebar
       rootStyles={{
    [`.${sidebarClasses.container}`]: {
      backgroundColor: 'transparent',
      // borderColor: '#efefef00'
    },
  }}
>
    
      {isMenuOpen &&(
      <Menu className={`cliked-menu${isMenuOpen ? 'open' : ''}`}>
        <MenuItem component={<Link to="/"></Link>}>Accueil</MenuItem>
        <MenuItem component={<Link to="/Contact"></Link>}>Contact</MenuItem>
        <MenuItem component={<Link to="/Animalier"></Link>}>Animalier</MenuItem>
        <SubMenu label ="Voyage :">
             <MenuItem component={<Link to="/Voyages/Dublin"></Link>}>Dublin</MenuItem>
             <MenuItem component={<Link to="/Voyages/Nouvelle-Zélande"></Link>}>Nouvelle-Zélande</MenuItem> 
             <MenuItem component={<Link to="/Voyages/Caraïbes"></Link>}>Caraïbes</MenuItem>     

        </SubMenu>  
        <MenuItem component={<Link to="/la_montagne"></Link>} >La Montagne</MenuItem>
        <MenuItem component={<Link to="/humain_volant"></Link>}>Humain Volant</MenuItem>
        <SubMenu label ="Monocrome :">
             <MenuItem component={<Link to="/Monocrome/Argentique "></Link>}>Argentique</MenuItem>
             <MenuItem component={<Link to="/Monocrome/Numérique"></Link>}>Numérique</MenuItem>     
        </SubMenu>  
    </Menu>
)}
  </Sidebar>
  </div>
  )
  
}

//   return (
//     <Sidebar className='Slidebar' collapsed={!isMenuOpen}>
//       <div className="menu-icon" onClick={toggleMenu}>
//         <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
//         <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
//         <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
//       </div>
//       <Menu className={`menu-items ${isMenuOpen ? 'open' : ''}`}>
//       
//       </Menu>
//     </Sidebar>
//   );
// };

export default TopMenu;


// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

// const TopMenu = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   return (
//     <div className="top-menu">
//       <div>
//       <div className="menu-icon" onClick={toggleMenu}>
//         <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
//         <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
//         <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
//       </div>
//       <ul className={`menu-items ${isMenuOpen ? 'open' : ''}`}>
//         <li><Link to="/">Accueil</Link></li>
//         <li><Link to="/contact">Contact</Link></li>
//         <li><Link to="/Animalier">Animalier</Link></li>
//         <li><Link to="/Voyages">Voyage : </Link></li>
//         <ul className={`menu-items-sub ${isMenuOpen ? 'open' : ''}`}>
//         <li><Link to="Voyages/Dublin">Dublin</Link></li>
//         <li><Link to="Voyages/Nouvelle-Zélande">Nouvelle-Zélande</Link></li>
//         </ul>
//         <li><Link to="/la_montagne">La Montagne</Link></li>
//         <li><Link to="/humain_volant">Humain Volant</Link></li>

//       </ul>
//     </div>
//     </div>
//   );
// };

// export default TopMenu;

// import React from 'react';
// import { Link } from 'react-router-dom';

// const TopMenu = () => {
//   return (
//     <div className="top-menu">
//       <ul>
//         <li><Link to="/">Accueil</Link></li>
//         <li><Link to="/contact">Contact</Link></li>
//         <li><Link to="/Animalier">Animalier</Link></li>
//         <li><Link to="/Voyages">Voyage</Link></li>
//         <li><Link to="/la_montagne">La Montagne</Link></li>
//         <li><Link to="/humain_volant">Humain Volant</Link></li>
//       </ul>
//     </div>
//   );
// };


// export default TopMenu;